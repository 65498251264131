













































































import { DELETE_INTEGRATION } from 'src/utils/apollo-mutations';
import { getOwnApps } from '../../utils/util';
import Vue from 'vue';
import { decodeToken } from '@shared/utils/commom.util';
import { LSS } from '@core/services';
import { logActivity } from 'src/utils/sam-activity-log';
export default Vue.extend({
  name: 'DeleteIntegration',
  props: {
    integrationDetailsForDeletion: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
      deleting: false,
       //activity log messages
       logType:"activity",
       logMessage:"Integration removed",
       featuredName: "ASA Integration",
       featuredPath: '',
       deleteIntStatus:''
    };
  },
  watch: {
    integrationDetailsForDeletion() {
      this.dialog = true;
    },
  },
  methods: {
    setDeleteTimer(deleteIntStatus,id){
      const indexToRemove = deleteIntStatus.findIndex(item => item.integrationId === id);

              if (indexToRemove !== -1) {
                 setTimeout(() => {
                  deleteIntStatus.splice(indexToRemove, 1);
                  LSS.deleteIntStatus = deleteIntStatus;
                  this.$emit('emitDeleteStatus', LSS.deleteIntStatus);
                },  10 * 60 * 1000); 
              } 
    },
    async deleteIntegration() {
        //activity logs When Apple Search Ads integration is removed:
        await logActivity(this.logType,this.logMessage,this.featuredName,this.featuredPath, {});
       this.deleting = true;
      try {
        const res = await this.$apollo.mutate({
          mutation: DELETE_INTEGRATION,
          variables: {
            identifier: this.integrationDetailsForDeletion.id,
            TeamID: this.integrationDetailsForDeletion.TeamID,
            type: this.integrationDetailsForDeletion.type
          },
        });
        if(res.data?.deleteIntegration?.successMessage=='Model is Queued') {
          const idExists = (LSS.deleteIntStatus.some(item => item.integrationId === this.integrationDetailsForDeletion.id));
        if(!idExists){
          this.deleteIntStatus = LSS.deleteIntStatus;
          this.deleteIntStatus.push({integrationId: this.integrationDetailsForDeletion.id});
          LSS.deleteIntStatus = this.deleteIntStatus;
          this.setDeleteTimer(LSS.deleteIntStatus,this.integrationDetailsForDeletion.id);
          this.$emit('emitDeleteStatus', LSS.deleteIntStatus);
        }
          this.deleting = false;
          this.dialog = false;
          this.$notify.success(this.$t('integration.intDeletionInProgress'));
        }
        if(res.data?.deleteIntegration?.code == 0) {
          this.$root.$emit('ASA-Int-Del', this.integrationDetailsForDeletion);
          await getOwnApps('asadel', this)
          this.deleting = false;
          this.dialog = false;
          window.location.reload();
        }
        
      } catch (error) {
        this.deleting = false;
      }
    },
  },
  mounted(){
    
  }
});
